/* 
.Links {
    color: whitesmoke;
    display: inline-block;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: #025FB5;;
    align-items: center;
    height: 50px;
   
}
.Links img:hover{
    border-radius: 20px;
    background-color: darkblue;
}
.Links a{
    color: whitesmoke;
}
.Links a:hover{
    color: black;
}

.head{
  
    background-color:whitesmoke;
}
li a{
    text-decoration: none;
    color: #025FB5;

    font-size: larger;
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
} 
ul{
      display: flex;
      justify-content: space-around;
      list-style: none;
      text-decoration: none;
      height: 45px;
      align-items: center;
      width: 77%;
  
}
.img{
  /* background-image: url('../Navbarlogo/Rectangle.png'); */
  /* width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  
}
.fade-in {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  height: 100%;
    width: 100%;
}

@keyframes fade-in {
  from {
    opacity: 2;
  }
  to {
    opacity: 1;
  }
}
.img-button{
  background-color: #4FA9FB;
margin-top: 100px;
border-radius: 40px;
padding: 17px 40px 14px 36px;
border: none;
color: whitesmoke;
}
.img-button:hover{
  background-color: black;
}
.img-text{
  position: absolute;
width: 150px;
height: 35px;
left: 695px;
top: 234px;
font-family: 'Merienda';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 35px;
text-align: justify-center;
color: whitesmoke;

}  */


.burger {
  position: relative;
  width: 40px;
  height: 30px;
  background: transparent;
  cursor: pointer;
  display: block;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  /* background: black; */
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-100%);
}

.burger input:checked ~ span:nth-of-type(1) {
  transform: rotate(45deg);
  top: 0px;
  left: 5px;
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 28px;
  left: 5px;
}





/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  /* z-index: 9999; */
  position: fixed; /* Stay in place */
  z-index: 99; /* Stay on top */
  top: 0;
  left: 0;
  background-color: whitesmoke; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 30px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  font-family: Gotham;
  color: #51ABFF;
  display: block;
  transition: 0.3s
}

/* When you mouse over the navigation links, change their color */

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}