/* 

.Img {
    background-image: url('../../Navbarlogo/Rectangle.png');
    background-repeat: no-repeat;
    height: 100vh;

}

.Img-Text {
    color: whitesmoke;
    font-size: xx-large;
}

.Main-Contain-text {
    color: #278CEA;
    text-align: center;
}.

sliding-element {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s, transform 0.5s;
}


.Main-Container-Box {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;
}

.box1 {
    height: 50vh;
    width: 50vh;
    background-color: red;
    box-sizing: border-box;
    border: 1px solid #278CEA;
}

.box2 {
    height: 50vh;
    width: 50vh;
    background-color: yellow;
    box-sizing: border-box;
    border: 1px solid #278CEA;
}

.box3 {
    height: 50vh;
    width: 50vh;
    /* background-color: green; */
    /* box-sizing: border-box; */
    /* border: 1px solid #278CEA;
} */


.sliding-element {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s, transform 0.5s;
  }
  .swiper-scrollbar-horizontal{
    display: none;
  }
  /* .custimise-swipe .swiper-slide{
    width: 100% !important;
  } */